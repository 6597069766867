.detailsContainer {
  background: linear-gradient(to bottom, #f0f6fc 600px, white 0);
}
.bkg {
  background-color: #f0f6fc;
}
.metadataHeader {
  font-weight: bold;
  color: #1c2024;
  font-size: 16px;
  letter-spacing: 1px;
}
.metadataHeader h2 {
  font-weight: bold;
  color: #1c2024;
  font-size: 16px;
  letter-spacing: 1px;
}

.padding {
  padding-left: 16px !important;
}

@media screen and (max-width: 992px) {
  .ps-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 520px) {
  .detailsContainer {
    background: linear-gradient(to bottom, #f0f6fc 600px, white 0);
  }
}
