.detailsCard {
  height: 291px;
}

.description {
  height: 170px;
  color: #19191a;
}

.scrollable {
  height: 170px;
  overflow: auto;
}

.descriptionText {
  font-size: 18px;
  line-height: 21px;
}


@media screen and (max-width: 992px) {
  .card {
    height: 170px;
    overflow: auto;
    width: 15em;
  }

  .text {
    font-size: 15px;
  }
}