.propertyName {
  font-weight: bold;
  font-size: 16px;
  color: #036;
}

.propertyValue {
  font-size: 16px;
  color: #19191a;
}

.propertyLink {
  font-size: 14px;
}

.assetLink:hover {
  color: #084d91 !important;
}

@media screen and (max-width: 992px) {
  .column {
    flex-direction: column ;
  }

  .propertyName {
    text-wrap: nowrap;
    font-size: 13px;
  }
}