.policy-body .btn {
  font-size: 18px;
  width: 239px;
  height: 56px;
}

.bold {
  font-weight: 600;
}

.footerTxtCnt{
  font-style: italic;
}