#rights-holders-input.notSelectedItems::placeholder {
  font-weight: 400;
}

#rights-holders-input.selectedItems::placeholder {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

#rights-holders-input[type="text"] {
  font-weight: 700;
}

#rights-holders-input-mobile.notSelectedItems::placeholder {
  font-weight: 400;
}

#rights-holders-input-mobile.selectedItems::placeholder {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

#rights-holders-input-mobile[type="text"] {
  font-weight: 700;
}

.dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

.optionsDropdown:hover {
  background-color: #dce9f5;
}

.optionsDropdown:first-child {
  padding-top: 10px !important;
}

@keyframes dropdownFadeIn {
  0% {
    /* opacity: 0; */
    margin-top: 0;
  }
  100% {
    /* opacity: 1; */
    margin-top: 16px;
  }
}
@keyframes dropdownFadeInTop {
  0% {
    /* opacity: 0; */
    margin-top: 0;
  }
  100% {
    /* opacity: 1; */
    margin-top: 16px;
  }
}

.dropdown-menu.show[data-popper-placement="top-start"] {
  animation: dropdownFadeInTop forwards 0.3s;
}

.dropdown-menu.show[data-popper-placement="bottom-start"] {
  animation: dropdownFadeIn forwards 0.3s;
}

#rightsHolderFilterMobile.show {
  margin-bottom: 365px;
  transition: margin-bottom 0.3s forwards;
}

#rightsHolderFilterMobile:not(.show) {
  margin-bottom: 0;
  transition: margin-bottom 0.3s forwards;
}
