.footer_logo_img {
  vertical-align: top;
  padding-top: 41px;
}

.istat-logo {
  height: 50px;
}

.dvr{
  /* height: 250px !important; */
  width:20px
}