.typeButton {
  cursor: pointer;
  width: 320px;
  height: 121px;
  font-size: 22px !important;
}
.fontExploreByTypeColor {
  color: #0066cc !important;
}
.footerBorderExploreByType {
  border-top: none !important;
}
